import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { IconButton, Button, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipNextSharpIcon from '@mui/icons-material/SkipNextSharp';
import SkipPreviousSharpIcon from '@mui/icons-material/SkipPreviousSharp';
import { toSecond } from '../../common/utilities';
import gameService from '../../services/game.service';
import EditIcon from '@mui/icons-material/Edit';
import PlayerTagEditRequestDialog from './PlayerTagEditRequestDialog'
import PlayerSelector from './PlayerSelector';
import { useTranslation } from 'react-i18next';

// import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
// import { positions, width } from '@mui/system';
import { TEAM_ICON_DEFAULT } from '../../common/staticData';

const styles = {
    action: {
        color: '#ffffff',
        fontSize: 14,
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
    },
    actionnormal: {
        color: '#ffffff',
        fontSize: 14,
        display: 'flex',
        width: '50%'
    },
    buttonBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px',
        width: '100%',
        position: 'fixed',
        bottom: 0,
        left: 0
    },
    actionControls: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto',
        direction: 'ltr'
    },
    correctionControl: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'space-between',
        margin: 'auto'
    },
    correctionAction: {
        border: '2px solid red',
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        fontSize: 16,
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        position: 'fixed',
        top: 0,
        left: 0
    },
    button: {
        color: '#ffffff',
        backgroundColor: '#80808069'
    }
};
export default function TagVideo({ tagList, muteState, setOpen, gameId, cur_player_id }) {
    const player = useRef(null);
    const [play, setPlay] = useState(true);
    const [ready, setReady] = useState(false);
    const [curIdx, setCurIdx] = useState(0);
    const [url, setUrl] = useState(tagList[0].url);
    const [videoURL, setVideoURL] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [playerList, setPlayerList] = useState([]);
    const [currPlayerId, setCurrPlayerId] = useState('');
    const [tagEditOpen, setTagEditOpen] = useState(false);
    const [playRate, setPlayRate] = useState(1.0);
    const isFirstClip = curIdx === 0;
    const isLastClip = curIdx === tagList.length - 1;
    const { t } = useTranslation();
    // const handleOpen = () => setModalOpen(true);
    const handleClose = () => {
        setModalOpen(false);
        setPlay(true);
    };

    useEffect(() => {
        if (url?.includes('youtube.com') || url?.includes('youtu.be')) {
            gameService.getNewStreamURL(url).then((res) => {
                setVideoURL(res.result.video_url);
            });
        } else setVideoURL(url);
    }, [url]);


    useEffect(() => {
        if (!ready) return;
        if (!tagList.length) return;
        playTagByIdx(0);
    }, [tagList, ready]);

    const seekTo = (sec) => player.current.seekTo(sec);

    const playTagByIdx = (i) => {
        var start_time = tagList[i]?.start_time ?? tagList[i]?.player_tag_start_time
        setVideoURL(tagList[i]?.url)
        setCurIdx(i)
        setTimeout(() => {
            seekTo(toSecond(start_time));
        }, 100)
    };

    const onProgress = (currentTime) => {
        var start_time = tagList[curIdx]?.start_time ?? tagList[curIdx]?.player_tag_start_time
        var end_time = tagList[curIdx]?.end_time ?? tagList[curIdx]?.player_tag_end_time
        const startTime = toSecond(start_time);
        const endTime = toSecond(end_time) - 3;

        if (currentTime <= startTime) {
            seekTo(startTime);
            setPlay(true);
        } else if (currentTime >= endTime) {
            if (curIdx < tagList.length - 1) PlayVideo(1);
            else {
                setPlay(true);
                setOpen(false);
            }
        }
    };

    const PlayVideo = (add) => {
        let index = curIdx + add;
        playTagByIdx(index);
    };

    // const clipCorrections = async (tags) => {
    //     let teamId;

    //     if (tags) {
    //         tags.map((data) => {
    //             teamId = data.team_id;
    //             setCurrPlayerId(data.player_id);
    //         });
    //     }

    //     await gameService.getGameTeamPlayersByTeam(teamId, gameId).then((res) => {
    //         setPlayerList(res);
    //         setPlay(false);
    //         handleOpen();
    //     });
    // };

    return (
        <>
            <PlayerSelector
                playerList={playerList}
                currPlayerId={currPlayerId}
                tagList={tagList}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                handleClose={handleClose}
                setPlay={setPlay}
                curIdx={curIdx}
                style={{ position: 'fixed', zIndex: 10000 }}
            />

            <div className="player-shared-wrapper tag-video">
                <ReactPlayer
                    className="react-shared-player"
                    url={videoURL}
                    muted={muteState}
                    // url={VIDEO}
                    ref={player}
                    onPlay={() => setPlay(true)}
                    onPause={() => setPlay(false)}
                    onReady={() => setReady(true)}
                    onProgress={(p) => onProgress(p.playedSeconds)}
                    playbackRate={playRate}
                    playing={play}
                    controls={false}
                    width="100%"
                    height="100%"
                />
            </div>
            <div
                style={{
                    position: 'fixed',
                    left: 8,
                    top: 8,
                    zIndex: 9999,
                    color: 'red'
                }}
                onClick={() => {
                    setPlay(false);
                    setTagEditOpen(true);
                    console.log("Edit button clicked!");
                }}
            >
                <EditIcon style={{ color: 'red', padding: 3, borderRadius: 60, textAlign: 'center', cursor: 'pointer' }} />
            </div>

            <IconButton
                aria-label="close"
                onClick={(e) => setOpen(false)}
                sx={{
                    position: 'fixed',
                    right: 8,
                    top: 8,
                    zIndex: 9999,
                    color: 'red'
                }}
            >
                <CloseIcon />
            </IconButton>

            {/* {ready && (
                <div style={styles.correctionControl} className="play-action-controls">
                    {!!tagList[curIdx] && (
                        <div style={styles.correctionAction}>
                            <EditIcon
                                style={{
                                    color: 'red',
                                    padding: 3,
                                    borderRadius: 60,
                                    textAlign: 'center',
                                    cursor: 'pointer'
                                }}
                                onClick={() => clipCorrections(tagList)}
                            />
                        </div>
                    )}
                </div>
            )} */}
            {ready && (
                <div style={styles.correctionControl} className="play-action-controls">
                    {!!tagList[curIdx] && (
                        <>
                            {/* <div style={styles.correctionAction}>
                            <EditIcon
                                style={{
                                    color: 'red',
                                    padding: 3,
                                    borderRadius: 60,
                                    textAlign: 'center',
                                    cursor: 'pointer'
                                }}
                                onClick={() => clipCorrections(tagList)}
                            />
                        </div>
                          <FormControlLabel control={<Switch checked={showLogo} onChange={(e) => setShowLogo(e.target.checked)} />} label="" sx={{ color: '#ffffff', margin: 0, flex: 0 }} /> */}
                            {tagList.length > 0 && curIdx < tagList.length && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', justifyContent: 'center', flex: 4 }}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#17375E', width: '110px', borderRadius: '5px' }}>
                                        <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '1.4rem', fontWeight: 500, color: '#ffffff' }}>
                                            {`${tagList[curIdx].period} - ${tagList[curIdx].time ?? tagList[curIdx].time_in_game}'`}
                                        </Typography>
                                    </div>
                                    <img src={tagList[curIdx].home_team_image ? tagList[curIdx].home_team_image : tagList[curIdx].home_team_logo ? tagList[curIdx].home_team_logo : TEAM_ICON_DEFAULT} style={{ width: '45px', height: '45px' }} alt='' />
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#ffffff', width: '90px', borderRadius: '5px' }}>
                                        <Typography sx={{ fontFamily: "'DM Sans', sans-serif", fontSize: '1.4rem', fontWeight: 500, color: '#17375E' }}>
                                            {`${tagList[curIdx].home_team_goals ?? tagList[curIdx].home_team_goal} : ${tagList[curIdx].away_team_goals ?? tagList[curIdx].away_team_goal}`}
                                        </Typography>
                                    </div>
                                    <img src={tagList[curIdx].away_team_image ? tagList[curIdx].away_team_image : tagList[curIdx].away_team_logo ? tagList[curIdx].away_team_logo : TEAM_ICON_DEFAULT} style={{ width: '45px', height: '45px' }} alt='' />
                                </div>

                            )}
                        </>
                    )}
                </div>
            )}

            {ready && (
                <div style={styles.actionControls} className="play-action-controls">
                    {window.innerWidth > 768 && !!tagList[curIdx] && (
                        <div style={styles.actionnormal}>
                            <div
                                style={{
                                    backgroundColor: 'rgb(62 62 62 / 62%)',
                                    padding: 5,
                                    borderRadius: 5,
                                    width: '40%',
                                    maxWidth: 100,
                                    textAlign: 'center'
                                }}
                            >
                                {tagList[curIdx]?.action_name ?? tagList[curIdx]?.action_names}
                            </div>
                            <div
                                style={{
                                    backgroundColor: 'rgb(254 124 1 / 69%)',
                                    padding: '5px 10px',
                                    borderRadius: 5
                                }}
                            >
                                #{tagList[curIdx]?.jersey} {tagList[curIdx]?.player_name ?? tagList[curIdx]?.player_names}.
                            </div>
                        </div>
                    )}
                    <div style={styles.buttonBox}>
                        {window.innerWidth < 768 && !!tagList[curIdx] && (
                            <div style={styles.action}>
                                <div
                                    style={{
                                        backgroundColor: 'rgb(62 62 62 / 62%)',
                                        padding: 5,
                                        borderRadius: 5,
                                        width: '40%',
                                        maxWidth: 100,
                                        textAlign: 'center'
                                    }}
                                >
                                    {tagList[curIdx]?.action_name ?? tagList[curIdx]?.action_names}
                                </div>
                                <div
                                    style={{
                                        backgroundColor: 'rgb(254 124 1 / 69%)',
                                        padding: '5px 10px',
                                        borderRadius: 5
                                    }}
                                >
                                    #{tagList[curIdx]?.jersey} {tagList[curIdx]?.player_name ?? tagList[curIdx]?.player_names}.
                                </div>
                            </div>
                        )}
                        <div>
                            <IconButton onClick={() => PlayVideo(-1)} style={styles.button} disabled={isFirstClip}>
                                <SkipPreviousSharpIcon color="#ffffff" />
                            </IconButton>
                            <Button variant="outlined" sx={{ width: '60px', color: '#ffffff' }} onClick={() => setPlayRate(0.5)}>
                                {'Slow'}
                            </Button>

                            <IconButton onClick={() => setPlay((p) => !p)} style={styles.button}>
                                {play ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>

                            <Button variant="outlined" sx={{ width: '60px', color: '#ffffff' }} onClick={() => setPlayRate((s) => s + 0.5)}>
                                {'Fast'}
                            </Button>
                            <IconButton onClick={() => PlayVideo(1)} style={styles.button} disabled={isLastClip}>
                                <SkipNextSharpIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            )}
            {tagList.length > 0 && (
                <PlayerTagEditRequestDialog
                    t={t}
                    open={tagEditOpen}
                    onClose={(flag) => {
                        setTagEditOpen(false);
                        setPlay(true);

                        // if (flag) setUpdated((c) => c + 1);
                    }}
                    player={tagList[curIdx]}
                    cur_player_id={cur_player_id}
                />
            )}
        </>
    );
}
