import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import GameService from '../../services/game.service';
import { MenuProps } from '../newcoach/components/common';
import '../newcoach/coach_style.css';

const PlayerTagEditRequestDialog = ({ open, onClose, player, cur_player_id, t }) => {
    const [playerList, setPlayerList] = useState([]);
    const [loading, setLoading] = useState(false); // State for loading indicator
    const [values, setValues] = useState({
        player_name: null,
    });

    const handleChange = (prop) => (e) => {
        setValues({ ...values, [prop]: e.target.value });
    };

    const handleTagSaveRequest = () => {
        setLoading(true); // Set loading to true before fetching data
        GameService.addCorrectionRequest(cur_player_id, values.player_name.player_id, player.tag_id).then(() => {
            console.log("Request Saved!!!");
            setLoading(false);
            onClose(true);
        });
    };

    useEffect(() => {
        (async () => {
            if (!player) return;

            setLoading(true); // Set loading to true before fetching data
            let players = [];
            await GameService.getGameTeamPlayersByTeam(player.team_id, player.game_id).then((res) => {
                console.log("player: ", player);
                console.log("Players: ", players);
                setPlayerList(res);
                players = res;
            });
            setValues({
                ...values,
                player_name: players.filter((p) => p.player_full_name === player.player_name)[0],
            });
            setLoading(false); // Set loading to false after data is fetched
        })();
    }, [player]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" inert={true}>
            <DialogTitle>{t('Change Player\'s Tag')}</DialogTitle>
            <DialogContent
                dividers
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    justifyContent: loading ? 'center' : 'flex-start', // Center content if loading
                    alignItems: loading ? 'center' : 'stretch', // Center spinner horizontally
                    height: '300px', // Fixed height for proper centering
                }}
            >
                {loading ? (
                    // Centered spinner
                    <CircularProgress />
                ) : (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <div>
                            <p className="normal-text" style={{ paddingLeft: '12px' }}>
                                {t('Players')}
                            </p>
                            <Select
                                value={values.player_name}
                                onChange={handleChange('player_name')}
                                label=""
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                inputProps={{ 'aria-label': 'Without label' }}
                                MenuProps={MenuProps}
                                sx={{
                                    borderRadius: '10px',
                                    outline: 'none',
                                    height: '36px',
                                    width: '200px',
                                    fontSize: '0.8rem',
                                    '& legend': { display: 'none' },
                                    '& fieldset': { top: 0 },
                                }}
                            >
                                {playerList.map((item) => (
                                    <MenuItem key={item.player_id} value={item}>
                                        {item.player_full_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                )}
            </DialogContent>
            {!loading && (
                <DialogActions>
                    <Button variant="outlined" onClick={() => onClose(false)}>
                        {t('Cancel')}
                    </Button>
                    <Button variant="outlined" onClick={() => handleTagSaveRequest()}>
                        {t('Request')}
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
};

export default PlayerTagEditRequestDialog;
